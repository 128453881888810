import styled from 'styled-components';

export const Wrapper = styled.div``;
export const SuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & .text-success {
    margin: 15px 0 0;
    font-weight: 600;
    font-size: 18px;
    color: #2a3b50;
  }
`;
export const AnimatedSuccess = styled.div`
  width: 250px;
`;
export const styleTextNote = styled.div`
  margin-top: 12px;
  display: flex;
  .text-note {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #2a3b50;
  }
  .icon {
    color: red;
    margin-right: 5px;
  }
`;
