import { Row } from 'antd';
import React, { useContext } from 'react';
import * as S from '../LoanManagement.style';
import IconPaymentSuccess from '@app/assets/icons/icon-payment-success.svg';
import ButtonDetail from '@app/components/common/buttons/ButtonDetail/ButtonDetail';
import { LoanManagementContext } from '../Context';
import { updateModalLoanManagement } from '../Action';

export default function ModalPaymentSuccess() {
  const { stateLoanManagement, dispatchLoanManagement } = useContext(LoanManagementContext);

  function handleOkSuccess() {
    dispatchLoanManagement(updateModalLoanManagement({ ...stateLoanManagement?.modals, isModalOpenSuccess: false, isModalOpenConfirm: false, isModalOpenPayment: false }));
  }

  return (
    <S.StyleModalPaymentSuccess title="Thanh toán khoản ứng vốn" footer={null} open={stateLoanManagement?.modals.isModalOpenSuccess} onOk={handleOkSuccess} onCancel={handleOkSuccess}>
      <div className="box-content">
        <Row className="section-logo">
          <img src={IconPaymentSuccess} alt="" />
        </Row>
        <Row className="text-h2">Đã gửi yêu cầu xác nhận thanh toán</Row>
        <div className="section-notification">
          <div className="box-notification">
            <div className="notification">Thông tin của bạn đã được ghi nhận, BiFiin sẽ xác nhận tất toán khoản ứng vốn trong 24h kể từ khi nhận tiền. Để nhận hỗ trợ vui lòng liên hệ</div>
            <div className="hotline">
              Hotline: <span className="value-blue">09.86.86.86.86</span>
            </div>
          </div>
        </div>
        <div className="section-btn">
          <ButtonDetail label={'Ok'} function={handleOkSuccess} class="style-btn"></ButtonDetail>
        </div>
      </div>
    </S.StyleModalPaymentSuccess>
  );
}
