import React, { useEffect, useState } from 'react';
import * as S from './ConnectAccountPage.style';
import AvatarBifiin from '@app/assets/icons/avatar-bifiin.svg';
import { Avatar, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ConnectPartnerRequest } from '../interfaces';
import { doConnectPartner } from '../Auth.api';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';
import { doFetchUserProfile } from '@app/modules/CustomerProfile/UpdateProfile/UpdateProfile.api';
import { PartnerInforType } from '@app/interfaces/interfaces';

export default function ConnectAccountPage() {
  const [profile, setProfile] = useState<any>();

  const partnerJson: any = localStorage.getItem('inforUserPartner');
  const partner: PartnerInforType = JSON.parse(partnerJson);
  const paramsPartnerJson: any = localStorage.getItem('paramsPartner');
  const paramsPartner = JSON.parse(paramsPartnerJson);
  const params: ConnectPartnerRequest = {
    identifyId: paramsPartner?.identify_id,
    uuidPartner: paramsPartner?.partner_id,
  };
  function moveOppsPage() {
    location.href = '/auth/opps';
  }

  async function movePartnerPage() {
    await doConnectPartner(params)
      .then((res) => {
        if (res?.error) {
          notificationController.error({
            message: _.get(
              res?.id,
              res?.error.error,
              `${res?.error?.message == 'The account has been linked to another BiFin account' ? 'Tài khoản hiện tại đã được liên kết với một tài khoản BiFin khác' : res?.error?.message}`,
            ),
          });
        } else {
          location.replace(`${res?.redirectUri}?access_token=${res?.accessToken}&nonce=${paramsPartner?.nonce}`);
          localStorage.setItem('UrlPartner', `${res?.redirectUri}?access_token=${res?.accessToken}&nonce=${paramsPartner?.nonce}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    doFetchUserProfile().then((res) => {
      setProfile(res);
    });
  }, []);

  return (
    <S.Container>
      <div className="box-content">
        <div className="avatar">
          <div className="boxavatar">
            <Avatar className="avatar-bifiin" src={AvatarBifiin}></Avatar>
            <div>
              <Avatar className="avatar-partner" src={`https://s3.ap-southeast-1.amazonaws.com/bifin.vn/${partner.uuid}.png`}></Avatar>
            </div>
          </div>
        </div>
        <div className="title">
          Liên kết tài khoản
          <span className="text-partner">{partner?.name}</span>
          với tài khoản Bifiin của bạn
        </div>
        <div className="infor-user">
          {/* <Avatar src={''} className="avatar-user"></Avatar> */}
          <div className="name">{profile?.user?.username && profile?.user?.username}</div>
        </div>
        <div className="description">
          <p className="text">Liên kết hai tài khoản với nhau để thuận tiện trong quá trình đặt đơn, ứng vốn</p>
          <InfoCircleOutlined className="icon" />
        </div>
        <div className="section-btn">
          <Button className="btn-reject" onClick={moveOppsPage}>
            Từ chối
          </Button>
          <Button className="btn-accept" onClick={movePartnerPage}>
            Cho phép
          </Button>
        </div>
      </div>
    </S.Container>
  );
}
