import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPages/Main';
import ConsumerLayout from '@app/components/layouts/ConsumerLayout/ConsumerLayout';
import AuthRoutes from '@app/modules/Auth/routes';
import CustomerProfileRoutes from '@app/modules/CustomerProfile/routes';
import CustomerPageRoutes from '@app/pages/routes';

import NotFoundLayout from '@app/components/layouts/NotFoundLayout/NotFoundLayout';
import SuspenseFallbackLayout from '@app/components/layouts/SuspenseFallbackLayout/SuspenseFallbackLayout';

export const DASHBOARD_PATH = '/';
const LogoutPage = React.lazy(() => import('@app/modules/Auth/LogoutPage/LogoutPage'));

const Dashboard = withLoading(DashboardPage);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <ConsumerLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={<SuspenseFallbackLayout />}>
        <Routes>
          <Route path={DASHBOARD_PATH} element={protectedLayout}>
            {CustomerPageRoutes.map((route) => {
              return <Route key={''} path={route.path} element={route.element} />;
            })}
            <Route index element={<Dashboard />} />
            <Route path="customer">
              {CustomerProfileRoutes.map((route) => {
                return <Route key={''} path={route.path} element={route.element} />;
              })}
            </Route>
          </Route>
          <Route path="/auth" element={<ConsumerLayout />}>
            {AuthRoutes.map((route) => {
              return <Route key={''} path={route.path} element={route.element} />;
            })}
          </Route>
          <Route path="/auth/logout" element={<LogoutPage />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<NotFoundLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
