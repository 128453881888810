import React from 'react';
import * as S from '../ConsumerLayout.style';
// import { useTranslation } from 'react-i18next';
import Logo from '@app/assets/logo-v3.svg';
import Menus from '@app/components/layouts/ConsumerLayout/Logged/Menus';
import UserActions from '@app/components/layouts/ConsumerLayout/Logged/UserActions';

const Header: React.FC = () => {
  // const { t } = useTranslation();
  return (
    <S.WrapperHeaderLogged>
      <S.HeaderLogo>
        <img src={Logo} className={'logo'} />
        {/* <span>{t('common.projectName')}</span> */}
      </S.HeaderLogo>
      <S.Menus>
        <Menus />
      </S.Menus>
      <S.UserActions>
        <UserActions />
      </S.UserActions>
    </S.WrapperHeaderLogged>
  );
};

export default Header;
