import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { ResponseDataTransactionAttention, TransactionType } from './inteface';

const httpApi = initAxios({});

interface UserProfileRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const UserProfileConfig: UserProfileRequestConfig = {
  secured: true,
};

export const getTransactionApi = (): Promise<TransactionType> => httpApi.get('/loans/v1/users/dashboards', { ...UserProfileConfig });

export const getTransactionAttentionApi = (params: string): Promise<ResponseDataTransactionAttention> => httpApi.get(`/loans/v1/users/dashboards/attentive?${params}`, { ...UserProfileConfig });
