import React, { lazy } from 'react';
import { AppRouterInterface } from '@app/interfaces/interfaces';

const UpdateProfilePage = lazy(() => {
  return import('./UpdateProfile/UpdateProfilePage');
});

const CustomerProfileRoutes: AppRouterInterface[] = [
  {
    path: 'update-profile',
    element: <UpdateProfilePage />,
  },
];

export default CustomerProfileRoutes;
