import React, { useContext, useEffect, useState } from 'react';
import * as S from '../LoanManagement.style';
import IconPaymentConfirm from '@app/assets/icons/icon-payment-confirm.svg';
import { Col, message, Row, Upload, UploadProps } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import ButtonDetail from '@app/components/common/buttons/ButtonDetail/ButtonDetail';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import ModalPaymentSuccess from './ModalPaymentSuccess';
import { LoanManagementContext } from '../Context';
import { updateModalLoanManagement } from '../Action';
import { confirmPaymentApi, UploadImgApi } from '../LoanManagement.api';
import { ResponseDataDetail } from '../interface';
type Props = {
  data?: ResponseDataDetail;
  amountCustomerWannaPayment?: any;
};
export default function ModalPaymentConfirm(props: Props) {
  const { stateLoanManagement, dispatchLoanManagement } = useContext(LoanManagementContext);
  const [object, setobject] = useState({
    code: props?.data ? props?.data?.code : '',
    amount: undefined,
    imageKey: '',
  });
  useEffect(() => {
    setobject({ ...object, amount: props?.amountCustomerWannaPayment ? props?.amountCustomerWannaPayment : undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object]);

  function handleOkConfirm() {
    dispatchLoanManagement(updateModalLoanManagement({ ...stateLoanManagement?.modals, isModalOpenConfirm: false }));
  }
  const { Dragger } = Upload;

  const propsUpdate: UploadProps = {
    name: 'file',
    multiple: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        const formData = new FormData();

        console.log(info.file);
        formData.append('file', info.file);

        UploadImgApi(formData)
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} file uploaded successfully.`);
              setobject({ ...object, imageKey: res?.key });
            } else {
              message.error(`${info.file.name} file  deleted `);
              setobject({ ...object, imageKey: '' });
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };
  const confirmPayment = async () => {
    if (object.amount) {
      await confirmPaymentApi({
        ...object,
      })
        .then((res) => {
          if (res?.error) {
            console.log('err');
          } else {
            dispatchLoanManagement(updateModalLoanManagement({ ...stateLoanManagement?.modals, isModalOpenSuccess: true }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <S.StyleModalPaymentConfirm title="Thanh toán khoản ứng vốn" footer={null} open={stateLoanManagement?.modals?.isModalOpenConfirm} onOk={handleOkConfirm} onCancel={handleOkConfirm}>
      <div className="box-content">
        <div className="section-logo">
          <img src={IconPaymentConfirm} alt="" />
        </div>
        <div className="section-note">Vui lòng thực hiện thanh toán bằng cách nạp tiền vào ví của Báo Gấm qua phương thức internet banking.</div>
        <Row className="section-table">
          <Col className="col-infor">
            <div className="line">
              <div className="label">Ngân hàng thụ hưởng</div>
              <div className="value">TPBank chi nhanh Tây HN</div>
            </div>
            <div className="line">
              <div className="label">Tên tài khoản nhận</div>
              <div className="value">Công ty Bao Gam</div>
            </div>
            <div className="line">
              <div className="label">Số tài khoản nhận</div>
              <div className="value">
                <Paragraph className="value" copyable={{ tooltips: false }}>
                  1223524734658
                </Paragraph>
              </div>
            </div>
            <div className="line">
              <div className="label">
                Cú pháp chuyển khoản<span className="icon-special-style">*</span>
              </div>
              <div className="value">
                <Paragraph className="value" copyable={{ tooltips: false }}>
                  <span className="value-blue">IDuser_contract_35824084</span>
                </Paragraph>
              </div>
            </div>
            <div className="line line-last">
              <div className="label">Tổng tiền thanh toán</div>
              <div className="value">
                <Paragraph className="value" copyable={{ tooltips: false }}>
                  <span className="value-blue">{props?.amountCustomerWannaPayment ? props?.amountCustomerWannaPayment : '0'}</span>
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col className="col-upload-file">
            <div className="save-image">
              <Dragger {...propsUpdate}>
                <p className="ant-upload-drag-icon">
                  <IconUploadFile />
                </p>
                <p className="ant-upload-text">
                  Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                </p>
              </Dragger>
            </div>
          </Col>
        </Row>
        <Row className="note-text">*Lưu ý: Vui lòng nhập đúng cú pháp chuyển khoản</Row>
        <Row className="section-btn">
          <ButtonDetail function={confirmPayment} label="Yêu cầu xác nhận thanh toán" class="style-btn"></ButtonDetail>
        </Row>
      </div>
      <ModalPaymentSuccess></ModalPaymentSuccess>
    </S.StyleModalPaymentConfirm>
  );
}
