import React, { useEffect } from 'react';
import { WithChildrenProps } from '@app/types/generalTypes';
import lottie from 'lottie-web';
import LoadingAnimation from '@app/assets/animated-json/loading-gray-3.json';
import * as S from './SuspenseFallbackLayout.style';

export const AnimatedLoadingLayout = () => {
  const elem = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const completed_anim = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: elem.current,
      animationData: LoadingAnimation,
      autoplay: true,
      loop: true,
    });

    return () => {
      completed_anim.destroy();
    };
  }, []);
  return (
    <S.Wrapper>
      <p ref={elem}></p>
    </S.Wrapper>
  );
};

const SuspenseFallbackLayout: React.FC<WithChildrenProps> = () => {
  return <AnimatedLoadingLayout />;
};

export default SuspenseFallbackLayout;
