import React from 'react';
import * as S from '@app/components/layouts/ConsumerLayout/ConsumerLayout.style';
import { Outlet } from 'react-router-dom';
import Header from '@app/components/layouts/ConsumerLayout/NonLogged/Header';

const NonLoggedLayout: React.FC = () => {
  return (
    <S.Wrapper>
      <Header />
      <S.MainNonLogged>
        <Outlet />
      </S.MainNonLogged>
    </S.Wrapper>
  );
};

export default NonLoggedLayout;
