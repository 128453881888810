import React, { useEffect } from 'react';
import { WithChildrenProps } from '@app/types/generalTypes';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import Animated404NotFound from '@app/assets/animated-json/404-not-found.json';
import lottie from 'lottie-web';
import * as S from './NotFoundLayout.style';

const NotFoundLayout: React.FC<WithChildrenProps> = () => {
  const elem = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const completed_anim = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: elem.current,
      animationData: Animated404NotFound,
      autoplay: true,
      loop: true,
    });

    return () => {
      completed_anim.destroy();
    };
  }, []);
  return (
    <>
      <PageTitle>404 NOT FOUND</PageTitle>
      <S.Wrapper>
        <p ref={elem} />
      </S.Wrapper>
    </>
  );
};

export default NotFoundLayout;
