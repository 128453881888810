import { Modal } from 'antd';
import styled from 'styled-components';
export const ContainerLoanManagement = styled.div`
  .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow {
    display: none;
  }
  /* width: 1200px;
  margin: 0 auto; */
  /* background-color: var(--background-color-container-consumer);
   */
  padding: 16px 0px 24px 24px;
  border: 1px solid #f3f3f3;
  background: #ffffff;
  .marginLeft {
    margin-left: 10px;
  }
  .section-formsearch {
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    input#keyword {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #6d8097;
    }
  }
  .box-startend {
    display: flex;
    width: 155px;
    .title {
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #2a3b50;
      margin-top: 5px;
    }
  }
  .box-flexend {
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-right: 24px !important;
    .add-filter {
      background: #597ef7;
      box-sizing: border-box;
      display: flex;
      border: 1px solid #597ef7;
      border-radius: 2px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
      padding: 3px 15px;
      margin-top: -16px;
      .style-icon {
        margin-top: 6px;
        margin-right: 8px;
        cursor: pointer;
      }
    }
    .export-excell {
      background: #fbd448;
      border: 1px solid #fbd448;
      border-radius: 2px;
      box-sizing: border-box;
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #1e2a39;
      padding: 4px 15px;
      margin-top: -16px;
      cursor: pointer;
      .style-icon {
        margin-top: 5px;
        margin-right: 8px;
      }
    }
  }
  .breadcrumb-css-code {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #597ef7;
  }
`;

export const StyleBreadCrumb = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #597ef7;
`;
export const LoanDetail = styled.div`
  padding: 20px 45px 20px 45px;

  .cursor-pointer {
    cursor: pointer;
  }
  .padding-top-0 {
    padding-top: 0 !important;
  }
  .padding-bottom-0 {
    padding-bottom: 0 !important;
  }
  .margin-top-24 {
    margin-top: 24px;
  }
  .section-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;

    .box-title {
      display: flex;
      .text-title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        color: #2a3b50;
        margin-left: 10px;
      }
    }
  }
  .section-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .col-1 {
      width: 49%;
      /* display: flex; */
      /* justify-content: space-between; */

      .section-infor-transaction {
        width: 100%;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #f3f3f3;
        .title-transaction {
          font-style: normal;
          font-weight: 600;
          padding: 16px 24px;
          border-bottom: 1px solid #ebebeb;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #3d536f;
          width: 100%;
        }
        .content {
          padding: 16px 24px;
          width: 100%;

          .box-content {
            width: 100%;

            .item {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 16px 0px;
              border-bottom: 1px solid #ebebeb;

              .branch {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #3d536f;
              }
              .value {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #2a3b50;
                display: flex;
                .style-icon-coppy {
                  margin-top: -2px;
                  margin-left: 10px;
                }
              }
              .value-blue {
                color: #597ef7;
              }
              .value-red {
                color: #ff4d4f;
              }
            }
            .border-bottom-none {
              border-bottom: none;
            }
          }
        }
      }
    }
    .col-2 {
      width: 49%;
      height: fit-content;
      .section-infor-amount {
        width: 100%;
        border: 1px solid #f3f3f3;
        background-color: #ffffff;
        border-radius: 4px;
        .title-amount {
          padding: 16px 24px;
          border-bottom: 1px solid #ebebeb;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #3d536f;
        }
        .content {
          padding: 17px 24px 10px 24px;
          width: 100%;

          .box-content {
            width: 100%;

            .item {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 16px 0px;
              border-bottom: 1px solid #ebebeb;

              .branch {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #3d536f;
              }
              .value {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #2a3b50;
                display: flex;
                .style-icon-coppy {
                  margin-top: -2px;
                  margin-left: 10px;
                }
              }
              .value-blue {
                color: #597ef7;
              }
            }
            .border-bottom-none {
              border-bottom: none;
            }
          }
        }
      }
      .box-content-col2 {
        border-radius: 4px;
        background-color: #ffffff;

        .title-partner {
          padding: 16px 24px;
          border-bottom: 1px solid #ebebeb;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #3d536f;
        }
        .content {
          padding: 16px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .col-avatar {
            .style-icon-partner {
              width: 100%;
              object-fit: contain;
            }
          }
          .col-infor {
            margin-left: 40px;

            width: 74%;

            .item {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 16px 0px;
              border-bottom: 1px solid #ebebeb;

              .branch {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #3d536f;
              }
              .value {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #2a3b50;
                display: flex;
                width: 60%;
                text-align: right;
                justify-content: right;
                .style-icon-coppy {
                  margin-top: -2px;
                  margin-left: 10px;
                }
              }
              .value-blue {
                color: #597ef7;
              }
            }
            .border-bottom-none {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
`;

export const StyleModalPayment = styled(Modal)`
  label.ant-form-item-required {
    display: none;
  }
  .ant-form-item {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
  .ant-modal-content {
    width: 895px;
    /* height: 650px; */
    margin-left: -150px;
  }

  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #3d536f;
  }
  .box-content {
    width: 65%;
    .section-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
  .ant-modal-body {
    display: flex;
    justify-content: center;
  }
  .text-confirm {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #2a3b50;
    .text-confirm-id {
      color: #597ef7;
      margin-left: 10px;
    }
  }
  .table {
    margin-top: 24px;
    display: flex;
    justify-content: center;

    .box-table {
      width: 451px;
      padding: 0px 24px;
      background: rgba(247, 250, 255, 0.7);
      border: 1px solid #f0f5ff;
      border-radius: 4px;
      box-sizing: border-box;
      margin-bottom: 24px;
      .line {
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        justify-content: space-between;
        padding: 16px 0px;
        .label {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #3d536f;
        }
        .value {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #2a3b50;
        }
        .value-blue {
          color: #597ef7;
        }
      }
    }
    .section-amount {
      width: 451px;
      border-top: 1px solid #f0f5ff;
      padding-top: 16px;

      .title-amount {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #597ef7;
      }
      .box-amount-number {
        /* border: 1px solid #d4dbe7; */
        border-radius: 4px;
        margin-top: 12px;
        justify-content: end;
        display: flex;
        input.ant-input {
          padding: 16px 24px;
          text-align: right;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
        .amount-style {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          text-align: justify;
          color: #2a3b50;
        }
      }
    }
  }
  .section-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    .btn-style {
      padding: 16px 68px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #1e2a39;
    }
  }
`;

export const StyleModalPaymentConfirm = styled(Modal)`
  .ant-modal-content {
    width: 1255px;
    /* height: 650px; */
    margin-left: -360px;
  }
  .ant-modal-body {
    padding: 10px 40px 48px 40px;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #3d536f;
  }
  .box-content {
    .section-logo {
      display: flex;
      justify-content: center;
    }
    .section-note {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #2a3b50;
      margin-top: 10px;
    }
    .section-table {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      .col-infor {
        width: 49%;
        height: 300px;
        box-sizing: border-box;
        background: #ffffff;
        border: 1.5px solid #d6e4ff;
        border-radius: 4px;
        padding: 28px 32px;
        .line {
          display: flex;
          justify-content: space-between;
          padding-bottom: 16px;
          border-bottom: 1px solid #f3f3f3;
          margin-bottom: 16px;
          .label {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #3d536f;
            .icon-special-style {
              color: red;
            }
          }
          .value {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #2a3b50;
          }
          .value-blue {
            color: #597ef7;
          }
        }
        .line-last {
          border-bottom: none;
          margin-bottom: 10px;
        }
      }
      .col-upload-file {
        width: 49%;
        height: 300px;
        background: #ffffff;
        /* border: 1.5px dashed #d6e4ff; */
        border-radius: 4px;
        .save-image {
          height: 100%;
        }
        span.ant-upload.ant-upload-btn {
          background: #ffffff;
          border-radius: 7px;
        }
        span.ant-upload-span {
          margin-top: -80px;
          padding: 0px 20px;
        }
      }
    }
    .note-text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      display: flex;
      align-items: center;
      color: #ff4d4f;
      margin-top: 12px;
    }
    .section-btn {
      display: flex;
      justify-content: center;
      .style-btn {
        padding: 16px 68px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #1e2a39;
        margin-top: 32px;
      }
    }
  }
`;
export const StyleModalPaymentSuccess = styled(Modal)`
  .ant-modal-content {
    width: 1047px;
    /* height: 650px; */
    margin-left: -260px;
  }
  .ant-modal-body {
    padding: 10px 40px 48px 40px;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #3d536f;
  }
  .box-content {
    .section-logo {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    .text-h2 {
      width: 100%;
      display: flex;
      margin-top: 31px;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #2a3b50;
    }
    .section-notification {
      display: flex;
      justify-content: center;
      .box-notification {
        width: 69%;
        .notification {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #2a3b50;
        }
        .hotline {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #2a3b50;
          .value-blue {
            color: #597ef7;
          }
        }
      }
    }
    .section-btn {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      .style-btn {
        background: #fbd448;
        border-radius: 4px;
        padding: 16px 68px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #1e2a39;
      }
    }
  }
`;
