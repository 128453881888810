import initAxios from '@app/api/API.v2';
import './mocks/Register.mock';
import * as I from '@app/modules/Auth/interfaces';
import { CustomGrantTokenConfig } from '@app/modules/Auth/interfaces';
import { AxiosRequestConfig } from 'axios';
interface Config extends AxiosRequestConfig {
  secured: boolean;
}
const httpApi = initAxios({});
const httpApiPartner = initAxios({ useBase: 'ops' });
const TokenConfig: Config = {
  secured: true,
};
export const doLogin = (params: I.LoginRequest): Promise<I.LoginResponse> => httpApi.post('/auth/v1/login', params);
export const doConnectPartner = (params: I.ConnectPartnerRequest): Promise<I.ConnectPartnerResponse> => httpApiPartner.post('/auth/v1/oauth2/token', { ...params }, { ...TokenConfig });

export const doRegister = (params: I.RegisterRequest): Promise<I.RegisterResponse> => httpApi.post('/auth/v1/register', params);
export const doOtpRegisterRequest = (params: I.RegisterUuidRequest): Promise<I.RequestOtpResponse> => httpApi.post('/auth/v1/register/request-otp', params);
export const doOtpRegisterVerify = (params: I.OtpVerifyRequest): Promise<I.VerifyOtpResponse> => httpApi.post('/auth/v1/register/verify-otp', params);
export const doResetPwdOtpRequest = (params: I.ResetPwdOtpRequest): Promise<I.ResetPwdOtpResponse> => httpApi.post('/auth/v1/reset-password', params);
export const doResetPwdOtpVerify = (params: I.ResetPwdOtpVerify): Promise<I.ResetPwdOtpVerifyResponse> => httpApi.post('/auth/v1/reset-password/verify-otp', { ...params });
export const doResetPwdOtpUpdate = (params: I.ResetPwdOtpUpdate, configs: CustomGrantTokenConfig): Promise<I.ResetPwdOtpUpdateResponse> =>
  httpApi.put('/auth/v1/reset-password/set-pwd', params, { ...configs });
