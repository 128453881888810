import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
`;
export const IconSuccess = styled.div`
  width: 200px;
`;
export const Text = styled.div`
  margin-top: 20px;
`;
