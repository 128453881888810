import React, { useEffect, useState } from 'react';
import * as S from './DashboardPage.styles';
import IconReject from '@app/assets/icons/icon-reject.svg';
import IconCancel from '@app/assets/icons/icon-cancel.svg';
import IconNodata from '@app/assets/icons/icon-nodata.svg';
import jwt_decode from 'jwt-decode';
import { getTransactionApi, getTransactionAttentionApi } from './Dashboard.api';
import { ResponseDataTransactionAttention, TransactionType, TypeParamsTransactionAttention } from './inteface';
import { Col, Row } from 'antd';
import ButtonDetail from '@app/components/common/buttons/ButtonDetail/ButtonDetail';
import { queryStringParams } from '@app/controllers/queryStringParams';
import SectionInforAmount from './ComponentDashboard/SectionInforAmount';
import SectionProduct from './ComponentDashboard/SectionProduct';
import SectionNotiEkycAccount from './ComponentDashboard/SectionNotiEkycAccount';
import { DashboardProvider } from './context';
import { GetUserTouchEkycAfterLocalStorage, SetUserTouchEkycAfterLocalStorage, readToken } from '@app/services/localStorage.service';
// import { useNavigate } from 'react-router-dom';

const DashboardPage: React.FC = () => {
  const [dataTransactionAttentionInfor, setDataTransactionInfor] = useState<TransactionType>();
  const UserInfor: any = jwt_decode(readToken());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkEkyc, setcheckEkyc] = useState(UserInfor.ekycStatus && UserInfor.ekycStatus);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataTransactionAttention, setDataTransactionAttention] = useState<ResponseDataTransactionAttention>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paramsTransactionAttention, setparamsTransactionAttention] = useState<TypeParamsTransactionAttention>({
    pageSize: 10000,
  });
  const [openModalNotiEkyc, setopenModalNotiEkyc] = useState(GetUserTouchEkycAfterLocalStorage() == true ? false : true);

  function handleOpenModalNoti() {
    setopenModalNotiEkyc(true);
  }

  function handleEkycAfter() {
    setopenModalNotiEkyc(false);
    SetUserTouchEkycAfterLocalStorage('true');
  }

  // const navigate = useNavigate();

  const callApiGetTransaction = async () => {
    getTransactionApi()
      .then((res) => {
        setDataTransactionInfor(res);
      })
      .catch((err) => {
        console.log(err);
        setDataTransactionInfor(undefined);
      });
  };
  const callApiGetTransactionAttention = async () => {
    getTransactionAttentionApi(queryStringParams(paramsTransactionAttention))
      .then((res) => {
        setDataTransactionAttention(res);
      })
      .catch((err) => {
        console.log(err);

        setDataTransactionAttention(undefined);
      });
  };

  useEffect(() => {
    callApiGetTransaction();
  }, []);

  useEffect(() => {
    callApiGetTransactionAttention();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsTransactionAttention]);

  // function showMore() {
  //   setparamsTransactionAttention({ ...paramsTransactionAttention, pageSize: paramsTransactionAttention.pageSize + 2 });
  // }

  function moveDetail(code: string) {
    location.href = `/loan-management/${code}/normal`;
    // navigate(`/boards/loan-management/${code}`);
  }

  return (
    <S.ContainerDashboard>
      {(checkEkyc && checkEkyc == 'FAILED') || (checkEkyc && checkEkyc == 'CANCELED') ? (
        <SectionNotiEkycAccount handleOpenModalNoti={handleOpenModalNoti} openModalNotiEkyc={openModalNotiEkyc} handleEkycAfter={handleEkycAfter}></SectionNotiEkycAccount>
      ) : null}
      <SectionInforAmount dataTransactionAttentionInfor={dataTransactionAttentionInfor}></SectionInforAmount>
      <Row className="row-2">
        <SectionProduct></SectionProduct>
        <Col className="transaction">
          <Row className="title">Giao dịch cần chú ý</Row>
          <div className="box-item">
            {dataTransactionAttention && dataTransactionAttention?.data?.length > 0 ? (
              dataTransactionAttention?.data?.map((item, index) => (
                <Row key={index} className="item">
                  <div className="box-content-take-border">
                    {item?.status == 'REJECTED' ? (
                      <div className="box-content-item">
                        <Row>
                          <img src={IconReject} alt="" />
                          <div className="text-reject">Giao dịch đã bị từ chối</div>
                        </Row>
                        <Row className="box-note">
                          <div className="space-empty"></div>
                          <div className="note">
                            Giao dịch <span className="text-reject-code">{item.code}</span> đã bị từ chối do <span>{item.reason}</span>.
                          </div>
                        </Row>
                        <Row className="box-btn">
                          <ButtonDetail function={() => moveDetail(item?.code)} label="Xem chi tiết"></ButtonDetail>
                        </Row>
                      </div>
                    ) : (
                      <div className="box-content-item">
                        <Row>
                          <img src={IconCancel} alt="" />
                          <div className="text-cancel">Giao dịch đã huỷ</div>
                        </Row>
                        <Row className="box-note">
                          <div className="space-empty"></div>

                          <div className="note">
                            Giao dịch <span className="text-cancel-code">{item.code}</span> bị huỷ do <span>{item.reason}</span>
                          </div>
                        </Row>
                        <Row className="box-btn">
                          <ButtonDetail function={() => moveDetail(item?.code)} label="Xem chi tiết"></ButtonDetail>
                        </Row>
                      </div>
                    )}
                  </div>
                </Row>
              ))
            ) : (
              <div className="box-content-nodata">
                <img src={IconNodata} alt="" />
              </div>
            )}
            {dataTransactionAttention && dataTransactionAttention?.data?.length > 0 ? (
              <Row className="section-show-more">
                <div className="text"></div>
              </Row>
            ) : (
              ''
            )}
          </div>
        </Col>
      </Row>
    </S.ContainerDashboard>
  );
};

// export default Main;

export default function Main() {
  return (
    <DashboardProvider>
      <DashboardPage></DashboardPage>
    </DashboardProvider>
  );
}
