import React, { useEffect, useState } from 'react';
import MainLayout from '@app/modules/Auth/MainLayout';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './ResetPasswordPage.style';
import SuccessAnimated from '@app/assets/animated-json/91001-success.json';
import lottie from 'lottie-web';
import { Button } from 'antd';
import { doClearResetPwdStates } from '@app/modules/Auth/Auth.slice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Navigate } from 'react-router-dom';

const ResetPasswordSuccessPage: React.FC = () => {
  const elem = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [keepAlive, setKeepAlive] = useState<boolean>(false);
  const { grantAccessToken } = useAppSelector((state) => state.auth.resetPwd);

  useEffect(() => {
    const completed_anim = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: elem.current,
      animationData: SuccessAnimated,
      autoplay: true,
      loop: false,
    });

    return () => {
      completed_anim.destroy();
    };
  }, []);

  useEffect(() => {
    dispatch(doClearResetPwdStates());
    setKeepAlive(true);
  }, [dispatch]);

  if (!grantAccessToken && !keepAlive) {
    return <Navigate to={'/auth/reset-password'} replace />;
  }

  return (
    <>
      <PageTitle>Thay đổi mật khẩu thành công</PageTitle>
      <MainLayout cover={false}>
        <S.SuccessWrapper>
          <S.AnimatedSuccess ref={elem} />
          <p className={'text-success'}>Thay đổi mật khẩu thành công</p>
          <Button
            type={'link'}
            style={{ color: 'blue', fontWeight: 400 }}
            onClick={() => {
              location.href = '/auth/login';
            }}
          >
            Quay trở lại đăng nhập
          </Button>
        </S.SuccessWrapper>
      </MainLayout>
    </>
  );
};

export default ResetPasswordSuccessPage;
