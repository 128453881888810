import React, { useEffect, useState } from 'react';
import * as S from '../ConsumerLayout.style';
import NotifyIcon from '@app/assets/icons/icon-notification.svg';
import { useResponsive } from '@app/hooks/useResponsive';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import type { MenuProps } from 'antd';
import { readToken } from '@app/services/localStorage.service';
import jwt_decode from 'jwt-decode';
import { responseApiGetInforUser } from '@app/modules/CustomerProfile/interfaces';
import { getUserProfileApi } from '@app/modules/CustomerProfile/UpdateProfile/UpdateProfile.api';

const ActionDropdown = styled(Dropdown)`
  cursor: pointer;
`;

const ActionDropdownMenu = styled.div`
  width: 289px;
  border-radius: 4px;

  & ul {
    padding: 24px 24px;

    & li {
      line-height: 33px;
    }
  }
`;

const UserActions: React.FC = () => {
  const { desktopOnly, isBigScreen } = useResponsive();
  const [dataUser, setdataUser] = useState<responseApiGetInforUser>();

  const items: MenuProps['items'] = [
    {
      label: <a href="/customer/update-profile">Thông tin của tôi</a>,
      key: '0',
    },
    // {
    //   label: <a href="/">Hỗ trợ</a>,
    //   key: '1',
    // },
    {
      label: <a href="/auth/logout">Thoát</a>,
      key: '2',
    },
  ];
  const uuidUser: any = jwt_decode(readToken());
  const callGetInforUser = async () => {
    await getUserProfileApi(uuidUser && uuidUser.uuid)
      .then((res) => {
        setdataUser(res);
      })
      .catch((err) => {
        console.log(err);
        setdataUser(undefined);
      });
  };

  useEffect(() => {
    callGetInforUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (desktopOnly || isBigScreen) {
    return (
      <S.UserActions>
        <S.NotifyBox>
          <img src={NotifyIcon} />
        </S.NotifyBox>
        <S.ProfileActions>
          <ActionDropdown
            dropdownRender={(menu) => {
              return <ActionDropdownMenu>{menu}</ActionDropdownMenu>;
            }}
            trigger={['click']}
            menu={{ items }}
            placement="topRight"
            arrow={{ pointAtCenter: true }}
          >
            <span>{dataUser && dataUser?.fullName}</span>
          </ActionDropdown>
        </S.ProfileActions>
      </S.UserActions>
    );
  }

  return null;
};

export default UserActions;
