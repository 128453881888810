import { amountType, FilterLoanManagementType, ModalLoanType, ReducerType } from './interface';

export const UPDATE_FILTERS_LOANMANAGEMENT = 'UPDATE_FILTERS_LOANMANAGEMENT';
export const UPDATE_MODALS_LOANMANAGEMENT = 'UPDATE_MODALS_LOANMANAGEMENT';
export const UPDATE_AMOUNT_PAYMENT = 'UPDATE_AMOUNT_PAYMENT';

UPDATE_AMOUNT_PAYMENT;
export const updateFiltersLoanManagement = (filtersLoanManagement: FilterLoanManagementType): ReducerType => {
  return {
    type: UPDATE_FILTERS_LOANMANAGEMENT,
    filtersLoanManagement: filtersLoanManagement,
  };
};
export const updateModalLoanManagement = (modal: ModalLoanType): ReducerType => {
  return {
    type: UPDATE_MODALS_LOANMANAGEMENT,
    modals: modal,
  };
};
export const updateAmountPayment = (amount: amountType): ReducerType => {
  return {
    type: UPDATE_AMOUNT_PAYMENT,
    amount: amount,
  };
};
