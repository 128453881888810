import React from 'react';
import NonLoggedLayout from '@app/components/layouts/ConsumerLayout/NonLogged/NonLoggedLayout';
import { useAppSelector } from '@app/hooks/reduxHooks';
import LoggedLayout from '@app/components/layouts/ConsumerLayout/Logged/LoggedLayout';

const ConsumerLayout: React.FC = () => {
  const token = useAppSelector((state) => state.auth.token);

  return token ? <LoggedLayout /> : <NonLoggedLayout />;
};

export default ConsumerLayout;
