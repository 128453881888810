import React from 'react';
import { AppRouterInterface } from '@app/interfaces/interfaces';
import RegisterSuccess from '@app/modules/Auth/RegisterSuccess/RegisterSuccess';
import ResetPasswordSuccessPage from '@app/modules/Auth/ResetPasswordPage/ResetPasswordSuccessPage';
import ConnectAccountPage from './ConnectAccountPage/ConnectAccountPage';
import OppsPage from './components/OppsPage/OppsPage';
import LoadingPageConnectPartner from './LoadingPageConnectPartner/LoadingPageConnectPartner';
import WithCheckLogged from '@app/hocs/WithCheckLogged';

const LoginPage = React.lazy(() => import('@app/pages/LoginPage'));
const LoginPageConnectPartner = React.lazy(() => import('@app/modules/Auth/LoginForm/v3/LoginConnectPartnerPage'));
const ResetPasswordPage = React.lazy(() => import('@app/modules/Auth/ResetPasswordPage/ResetPasswordPage'));
const ResetPwdConfirmOTPPage = React.lazy(() => import('@app/modules/Auth/ResetPasswordPage/ResetPwdConfirmOTPPage'));
const ResetPwdUpdatePage = React.lazy(() => import('@app/modules/Auth/ResetPasswordPage/ResetPwdUpdatePage'));
const RegisterConfirmOtpPage = React.lazy(() => import('@app/modules/Auth/RegisterConfirmOtp/RegisterConfirmOtpPage'));
const RegisterFillFormPage = React.lazy(() => import('@app/modules/Auth/RegisterFillForm/RegisterFillFormPage'));

const AuthRoutes: AppRouterInterface[] = [
  {
    path: 'login',
    element: WithCheckLogged(LoginPage),
  },
  {
    path: 'login/connect-partner',
    element: WithCheckLogged(LoginPageConnectPartner),
  },

  {
    path: 'reset-password',
    element: WithCheckLogged(ResetPasswordPage),
  },
  {
    path: 'reset-password/confirm-otp',
    element: WithCheckLogged(ResetPwdConfirmOTPPage),
  },
  {
    path: 'reset-password/update',
    element: WithCheckLogged(ResetPwdUpdatePage),
  },
  {
    path: 'reset-password/success',
    element: WithCheckLogged(ResetPasswordSuccessPage),
  },
  {
    path: 'register/confirm-otp',
    element: WithCheckLogged(RegisterConfirmOtpPage),
  },
  {
    path: 'register/form',
    element: WithCheckLogged(RegisterFillFormPage),
  },
  {
    path: 'register/success',
    element: WithCheckLogged(RegisterSuccess),
  },
  {
    path: `partner/authorize-connect/`,
    element: <LoadingPageConnectPartner />,
  },
  {
    path: `partner/confirm-connect`,
    element: <ConnectAccountPage />,
  },
  {
    path: `opps`,
    element: <OppsPage />,
  },
];

export default AuthRoutes;
