import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import React from 'react';
import IconAdvance from '@app/assets/icons/icon-advance.svg';
import IconTotalAmount from '@app/assets/icons/icon-total-amount.svg';
import IconTransactionActive from '@app/assets/icons/icon-transaction-active.svg';
import { TransactionType } from '../inteface';
import { formatNumberWithCommas } from '@app/utils/utils';

type Props = {
  dataTransactionAttentionInfor: TransactionType | undefined;
};

export default function SectionInforAmount(props: Props) {
  // const text1 = <span className="text-tooltip">Giao dịch chờ duyệt</span>;
  const text3 = <span className="text-tooltip">Tổng tiền đã giải ngân ứng với khoản vay đang hoạt động</span>;

  return (
    <Row className="row-infor-amount">
      <div className="item advance">
        <div className="box-icon">
          <img src={IconAdvance} alt="" />
        </div>
        <div className="content">
          <div className="row-label">
            <div className="text-css">Số giao dịch chờ duyệt</div>
            {/* <Tooltip placement="top" title={text1}>
              <InfoCircleOutlined className="style-icon" />
            </Tooltip> */}
          </div>
          <div className="value">
            {props?.dataTransactionAttentionInfor && props?.dataTransactionAttentionInfor?.inReview ? formatNumberWithCommas(props?.dataTransactionAttentionInfor?.inReview) : '-'}
          </div>
        </div>
      </div>
      <div className="item transaction-active">
        <div className="box-icon">
          <img src={IconTransactionActive} alt="" />
        </div>
        <div className="content">
          <div className="row-label">
            <div className="text-css">Số giao dịch hoạt động</div>
          </div>
          <div className="value">
            {props?.dataTransactionAttentionInfor && props?.dataTransactionAttentionInfor?.inActive ? formatNumberWithCommas(props?.dataTransactionAttentionInfor?.inActive) : '-'}
          </div>
        </div>
      </div>
      <div className="item total-amount">
        <div className="box-icon">
          <img src={IconTotalAmount} alt="" />
        </div>
        <div className="content">
          <div className="row-label">
            <div className="text-css">Tổng tiền đang ứng vốn</div>
            <Tooltip placement="top" title={text3}>
              <InfoCircleOutlined className="style-icon" />
            </Tooltip>
          </div>
          <div className="value">
            {props?.dataTransactionAttentionInfor && props?.dataTransactionAttentionInfor?.amount ? formatNumberWithCommas(props?.dataTransactionAttentionInfor?.amount) : '-'}
          </div>
        </div>
      </div>
    </Row>
  );
}
