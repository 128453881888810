import React from 'react';
import { withLoading } from './withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPages/Main';
import { readToken } from '@app/services/localStorage.service';

// eslint-disable-next-line @typescript-eslint/ban-types

const WithCheckLogged: React.FC<any> = (Component: any) => {
  const token = readToken();

  const Dashboard = withLoading(DashboardPage);
  return token ? <Dashboard /> : <Component />;
};

export default WithCheckLogged;
