import React from 'react';
import { initDashboard, useDashboardReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const DashboardContext = React.createContext({ ...initDashboard });
export const DashboardProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateDashboard, dispatchDashboard] = useDashboardReducer();

  const wrap = {
    stateDashboard,
    dispatchDashboard,
  };

  return <DashboardContext.Provider value={wrap}>{children}</DashboardContext.Provider>;
};
