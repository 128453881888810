import styled from 'styled-components';
import { media } from '@app/styles/themes/constants';

export const Wrapper = styled.div`
  padding-bottom: 30px;
  background: var(--background-color-consumer);
`;
export const WrapperHeader = styled.div`
  background: #f9f9fa;
  box-shadow: 0px 4px 12px rgba(192, 192, 229, 0.25);
  height: 80px;
  padding: 16px 80px;
  font-size: 32px;

  & img.logo {
    /* width: 48px;
    height: 48px; */
  }
`;
export const WrapperHeaderLogged = styled.div`
  display: flex;
  justify-content: space-between;
  background: #19222d;
  box-shadow: 0px 4px 12px rgba(192, 192, 229, 0.25);
  height: 64px;
  padding: 12px 160px;
  font-size: 28px;

  @media only screen and ${media.xl} {
    padding: 12px 160px;
  }

  @media only screen and ${media.xs} {
    padding: 12px 50px;
  }

  @media only screen and ${media.sm} {
    padding: 12px 30px;
  }

  & img.logo {
    /* width: 39px;
    height: 39px; */
  }
`;
export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;

  & span {
    font-style: normal;
    font-weight: 500;
    line-height: 39px;
    color: var(--bifiin-logo-text-color);
  }
`;
export const Menus = styled.div`
  display: flex;
  align-items: center;
`;
export const UserActions = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  color: #d9d9d9;
  align-items: center;
  gap: 15px;
`;
export const MainNonLogged = styled.div``;
export const MainLogged = styled.div`
  /* padding: 12px 160px; */

  @media only screen and ${media.xl} {
    padding: 12px 160px;
  }

  @media only screen and ${media.xs} {
    padding: 12px 30px;
  }

  @media only screen and ${media.sm} {
    padding: 12px 30px;
  }
`;
export const NotifyBox = styled.div``;
export const ProfileActions = styled.div``;
export const BreadcrumbBox = styled.div`
  border-bottom: 1px solid #f3f3f3;
  padding-left: 45px;
  padding-bottom: 20px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  .style-text-breadcrumb {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.45);
  }
  & h2.title {
    margin: 19px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
`;
