import { filtersDashboard, ReducerType, StateModalType } from './inteface';

export const UPDATE_FILTERS_DASHBOARD = 'UPDATE_FILTERS_DASHBOARD';
export const UPDATE_MODALS_DASHBOARD = 'UPDATE_MODALS_DASHBOARD';

export const updateFiltersDashboard = (filtersDashboard: filtersDashboard): ReducerType => {
  return {
    type: UPDATE_FILTERS_DASHBOARD,
    filtersDashboard: filtersDashboard,
  };
};

export const updateModals = (stateModal: StateModalType): ReducerType => {
  return {
    type: UPDATE_MODALS_DASHBOARD,
    stateModal: stateModal,
  };
};
