import styled from 'styled-components';
import { Col, Modal } from 'antd';
import { LAYOUT, media } from '@app/styles/themes/constants';

export const RightSideCol = styled(Col)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(Col)`
  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    height: calc(100vh - ${LAYOUT.desktop.headerHeight});
    overflow: auto;
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;
export const SectionNotiEkycAccount = styled.div`
  background: #ffffff;
  border: 1px solid #d6e4ff;
  border-radius: 4px;
  padding: 22px 28px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  .box-start {
    display: flex;
    img {
      width: 25px;
    }
    .text-noti {
      margin-left: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: #3d536f;
    }
  }
  .box-end {
  }
`;

export const StyleModalNotiEkyc = styled(Modal)`
  .ant-modal-close-x {
    display: none !important;
  }
  .box-content-modal {
    padding-left: 140px;
    padding-right: 140px;
    .row-img {
      display: flex;
      justify-content: center;
    }
    .row-text {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      color: #3d536f;
      margin-top: 30px;
      width: 492px;
    }
    .box-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      padding-bottom: 30px;
    }
  }
  .ant-modal-content {
    width: max-content;
    margin-left: -130px;
  }
  .item {
    width: 48% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
  }
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const ContainerDashboard = styled.div`
  .text-tooltip {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3d536f;
  }
  /* width: 1200px; */
  /* margin: 0 auto; */
  padding-bottom: 24px;
  padding-left: 40px;
  padding-right: 40px;
  .row-infor-amount {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .item {
      width: 32%;
      padding: 26px 32px;
      border-radius: 4px;
    }
    .advance {
      background: #eaf8ff;
      border: 1px solid #bae7ff;
      display: flex;
      .content {
        .row-label {
          margin-left: 24px;
          display: flex;
          margin-bottom: 8px;
          .text-css {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #3d536f;
          }
          .style-icon {
            margin-top: 5px;
            margin-left: 5px;
            font-size: 14px;
            color: #8b9db3;
          }
        }
        .value {
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 37px;
          color: #40a9ff;
          margin-left: 24px;
        }
      }
    }
    .transaction-active {
      background: #fff2f7;
      border: 1px solid #ffd6e7;
      display: flex;

      .content {
        .row-label {
          margin-left: 24px;
          display: flex;
          margin-bottom: 8px;
          .text-css {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #3d536f;
          }
          .style-icon {
            margin-top: 5px;
            margin-left: 5px;
            font-size: 14px;
          }
        }
        .value {
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 37px;
          color: #f759ab;
          margin-left: 24px;
        }
      }
    }
    .total-amount {
      background: #f2f6ff;
      border: 1px solid #d6e4ff;
      display: flex;

      .content {
        .row-label {
          margin-left: 24px;
          display: flex;
          margin-bottom: 8px;
          .text-css {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #3d536f;
          }
          .style-icon {
            margin-top: 5px;
            margin-left: 5px;
            font-size: 14px;
            color: #8b9db3;
          }
        }
        .value {
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 37px;
          color: #597ef7;
          margin-left: 24px;
        }
      }
    }
  }
  .row-2 {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    .product {
      width: 66%;
      background: #ffffff;
      border: 1px solid #f3f3f3;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 24px;
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        color: #2a3b50;
      }
      .content {
        margin-top: 16px;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        .itemCOD {
          border: 1px solid #f0f5ff;
          filter: drop-shadow(0px 4px 12px rgba(192, 192, 229, 0.25));
        }
        .item {
          width: 48.5%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          background: #ffffff;
          border: 1px solid #f9f0ff;
          filter: drop-shadow(0px 4px 12px rgba(192, 192, 229, 0.25));
          border-radius: 8px;

          .content {
            padding: 13px 24px 35px 24px;
            display: block;
            .title-import {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 26px;
              color: #2a3b50;
            }
            .title-cod {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 26px;
              color: #2a3b50;
            }
            .text {
              margin-top: 12px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: #3d536f;
            }
            .detail {
              display: flex;
              justify-content: end;
              margin-top: 12px;
              .box-detail {
                display: flex;
                cursor: pointer;
                .text-detail-import {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  text-align: right;
                  color: #9254de;
                  margin-right: 35px;
                }
                .style-icon-arrow-import {
                  color: #9254de;
                  display: flex;
                  align-items: center;
                  font-size: 13px;
                }
                .text-detail-cod {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  text-align: right;
                  color: #597ef7;
                  margin-right: 35px;
                }
                .style-icon-arrow-cod {
                  color: #85a5ff;
                  display: flex;
                  align-items: center;
                  font-size: 13px;
                }
              }
            }
          }
          .image {
            margin-top: 10px;
            display: flex;
            justify-content: center;
            img {
              width: 100%;
              border-radius: 8px;
            }
          }
        }
      }
    }
    .transaction {
      width: 32%;
      background: #ffffff;
      border: 1px solid #f3f3f3;
      border-radius: 4px;
      box-sizing: border-box;
      .box-content-nodata {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 375px;
      }
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #3d536f;
        border-bottom: 1px solid #f3f3f3;
        padding: 24px 24px 16px 24px;
        position: sticky;
        z-index: 9;
        background: #fff;
        top: 0;
      }
      .box-item {
        height: 427px;
        overflow-y: overlay;
        overflow-x: hidden;
      }
      .item {
        padding: 0px 16px;
        width: 100%;

        .box-content-take-border {
          border-bottom: 1px solid #f0f5ff;
          width: 100%;

          .box-content-item {
            padding: 16px;
            width: 100%;

            .text-reject {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: #ff4d4f;
              display: flex;
              align-items: center;
              margin-left: 10px;
            }
            .text-cancel {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: #eb2f96;
              display: flex;
              align-items: center;
              margin-left: 10px;
            }
            .box-note {
              display: flex;
              flex-wrap: inherit;
              margin-top: 10px;
              .space-empty {
                width: 36px;
                height: 36px;
              }
              .note {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #2a3b50;
                margin-left: 10px;
                .text-reject-code {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 22px;
                  color: #ff4d4f;
                }
                .text-cancel-code {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 22px;
                  color: #eb2f96;
                }
              }
            }
            .box-btn {
              display: flex;
              justify-content: end;
              width: 100%;
              margin-top: 16px;
            }
          }
        }
      }
      .section-show-more {
        display: flex;
        justify-content: center;
        padding: 30px 32px 24px 32px;
        position: sticky;
        z-index: 9;
        background: #fff;
        bottom: -1px;
        margin-top: 25px;
        background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 96.51%);
        .text {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #ffa940;
        }
      }
    }
  }
`;
