import { HEX } from '@app/constants/creditScoring';

export type StatusesTypo =
  | 'DONE'
  | 'WAIT_FOR_DISBURSEMENT'
  | 'DRAFT'
  | 'OVERDUE'
  | 'ACTIVE'
  | 'RECEIVE'
  | 'REJECTED'
  | 'APPROVED'
  | 'WAIT_FOR_APPROVE'
  | 'INACTIVE'
  | 'OVERDUE'
  | 'RECALL'
  | 'wait_for_outgoing'
  | 'TO_DUE_SOON'
  | 'CANCELED'
  | 'WAIT_FOR_VERIFY_OTP'
  | 'IN_ACTIVE'
  | 'SETTLEMENT';
export type StatusesContextType = 'CUSTOMER' | 'LOAN';
export type StatusAntCode = 'success' | 'error' | 'default' | 'processing' | 'warning' | 'gold' | HEX;

type StatusEnumType = {
  [key in StatusesContextType]?: {
    [key in StatusesTypo]?: {
      colorOrigin: StatusAntCode;
      text: string;
      ts?: string; // optional for use translation, in the case passed a ts field, will be use this field instead text
      color?: string | ''; // optional for force use color as defining instead colorOrigin
    };
  };
};

export const StatusDefinitionEnum: StatusEnumType = {
  CUSTOMER: {
    ACTIVE: {
      colorOrigin: 'success',
      text: 'Hoạt động',
      color: '#73D13D',
    },
    IN_ACTIVE: {
      colorOrigin: 'success',
      text: 'Ngưng HĐ',
      color: '#595959',
    },
    WAIT_FOR_VERIFY_OTP: {
      colorOrigin: 'success',
      color: '#FA8C16',
      text: 'Chưa xác thực',
    },
  },
  LOAN: {
    DRAFT: {
      color: '#D9D9D9',
      colorOrigin: '#D9D9D9',
      text: 'Nháp',
    },
    WAIT_FOR_APPROVE: {
      color: '#FA8C16',
      colorOrigin: '#FA8C16',
      text: 'Chờ duyệt',
    },
    APPROVED: {
      color: '#52C41A',
      colorOrigin: 'processing',
      text: 'Đã duyệt',
    },

    REJECTED: {
      colorOrigin: '#FF4D4F',
      color: '#FF4D4F',
      text: 'Từ chối',
    },
    WAIT_FOR_DISBURSEMENT: {
      color: '#FF7A45',
      colorOrigin: '#FF7A45',
      text: 'Chờ giải ngân',
    },
    OVERDUE: {
      colorOrigin: '#FF4D4F',
      text: 'Quá hạn',
      color: '#FF4D4F',
    },
    DONE: {
      colorOrigin: '#597EF7',
      color: '#597EF7',
      text: 'Đã tất toán',
    },
    RECALL: {
      colorOrigin: '#FF4D4F',
      color: '#FF4D4F',
      text: 'Đã thu hồi',
    },
    CANCELED: {
      colorOrigin: '#D4DBE7',
      text: 'Đã hủy',
      color: '#D4DBE7',
    },
    ACTIVE: {
      colorOrigin: '#52C41A',
      text: 'Đang hoạt động',
      color: '#52C41A',
    },
  },
};
