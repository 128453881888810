import axios, { AxiosInstance, AxiosResponse } from 'axios';
import _ from 'lodash';
import { readToken } from '@app/services/localStorage.service';
import { ResponseError } from '@app/interfaces/interfaces';
import { handleLogout } from '@app/utils/utils';

export interface GeneralResponseApi extends AxiosResponse {
  data: ResponseError | any;
}

export interface AxiosConstructor {
  useBase?: 'consumer' | 'ops' | undefined;
}
const initAxios = ({ useBase }: AxiosConstructor): AxiosInstance => {
  const httpApi = axios.create({
    baseURL: useBase == 'consumer' || useBase == undefined ? process.env.REACT_APP_GATEWAY_URL : process.env.REACT_APP_PARTNER_GATEWAY_URL,
    validateStatus: function () {
      return true;
    },
  });

  httpApi.interceptors.request.use((config) => {
    if (_.get(config, 'secured', false)) {
      config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };
    }
    if (_.get(config, 'ContentType', false)) {
      config.headers = {
        ...config.headers,
        'Content-Type': _.toString(_.get(config, 'ContentType')),
      };
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      };
    }

    return config;
  });

  httpApi.interceptors.response.use(
    (resp: GeneralResponseApi) => {
      if (_.includes([403], resp.status) && resp.data.error !== 'auth-018') {
        localStorage.removeItem('oauth2Token');
        handleLogout();
        location.href = '/';
      }

      if (_.includes([200, 201], resp.status) && typeof resp.data !== 'undefined') {
        if (typeof resp.data.error !== 'undefined' && resp.data.error === 'C-008') {
          handleLogout();
          // Expired token or invalid token

          location.href = '/auth/login';
        }

        return resp.data;
      } else {
        return {
          error: {
            data: resp.data ?? null,
            message: resp?.data?.message ?? null,
            status: resp.status,
            code: resp?.data?.code ?? -1,
          },
        };
      }
    },
    (error) => {
      return {
        error: 'I-1000',
        id: -1,
        message: error,
      };
    },
  );

  return httpApi;
};

export default initAxios;
