import React from 'react';
import { initLoanManagement, useLoanManagementReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const LoanManagementContext = React.createContext({ ...initLoanManagement });
export const LoanManagementProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateLoanManagement, dispatchLoanManagement] = useLoanManagementReducer();

  const wrap = {
    stateLoanManagement,
    dispatchLoanManagement,
  };

  return <LoanManagementContext.Provider value={wrap}>{children}</LoanManagementContext.Provider>;
};
