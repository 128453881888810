import React, { useState } from 'react';
import * as S from './ButtonBlue.style';

interface Props {
  label: string;
  function?: (e: any) => void;
  class?: string | '';
  padding?: string;
}

export default function ButtonBlue(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [className, setclassName] = useState(`style-button ${props.class}`);
  return (
    <S.StyleButtonBlue style={{ padding: `${props?.padding}` }} onClick={props.function} className={className}>
      {props.label}
    </S.StyleButtonBlue>
  );
}
