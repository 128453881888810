import React from 'react';
import * as S from './OppsPage.style';
import OppsIcon from '@app/assets/icons/icon-opps.svg';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ResponsePartner } from '../../LoadingPageConnectPartner/interface';

export default function OppsPage() {
  const partnerJson: any = localStorage.getItem('inforUserPartner');
  const partner: ResponsePartner = JSON.parse(partnerJson);

  function moveConnectPage() {
    location.href = '/auth/partner/confirm-connect';
  }
  function movePartnerPage() {
    // window.open = `${partner?.redirectPartner}`;
    if (partner?.profilePartnerUri) {
      location.replace(`${partner?.profilePartnerUri}`);
    }
  }
  return (
    <S.Container>
      <div className="box-content">
        <div className="box-img">
          <img src={OppsIcon} alt="" />
        </div>
        <div className="title">Opps</div>
        <div className="description">Bạn cần xác nhận liên kết tài khoản để tham gia ứng vốn tới 80% giá trị đơn hàng.</div>
        <div className="description-note">
          <p className="text">Liên kết hai tài khoản với nhau để thuận tiện trong quá trình đặt đơn, ứng vốn</p>
          <InfoCircleOutlined className="icon" />
        </div>
        <div className="section-btn">
          <Button className="btn-reject" onClick={movePartnerPage}>
            Quay lại
          </Button>
          <Button className="btn-accept" onClick={moveConnectPage}>
            Thử lại
          </Button>
        </div>
      </div>
    </S.Container>
  );
}
