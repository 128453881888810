import React from 'react';
import * as S from '@app/components/layouts/ConsumerLayout/ConsumerLayout.style';
import { Outlet } from 'react-router-dom';
import Header from '@app/components/layouts/ConsumerLayout/Logged/Header';
import { Space, Breadcrumb } from 'antd';
import { BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { DASHBOARD_PATH } from '@app/components/router/AppRouter';

const LoggedLayout: React.FC = () => {
  const breadcrumbs = useAppSelector((state) => state.breadcrumb);

  return (
    <S.Wrapper>
      <Header />
      <S.MainLogged>
        <S.BreadcrumbBox>
          <Breadcrumb>
            {breadcrumbs.items.length > 0 ? (
              <BreadcrumbItem href={DASHBOARD_PATH}>
                <Space>
                  <span className="style-text-breadcrumb">Trang chủ</span>
                </Space>
              </BreadcrumbItem>
            ) : null}
            {breadcrumbs.items.map((breadcrumb) => {
              return (
                <React.Fragment key={breadcrumb.name}>
                  <BreadcrumbItem href={breadcrumb.url ?? null}>
                    <Space>
                      {breadcrumb.icon ?? ''}
                      <span className="style-text-breadcrumb">{breadcrumb.name}</span>
                    </Space>
                  </BreadcrumbItem>
                </React.Fragment>
              );
            })}
          </Breadcrumb>
        </S.BreadcrumbBox>
        <Outlet />
      </S.MainLogged>
    </S.Wrapper>
  );
};

export default LoggedLayout;
