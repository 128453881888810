import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  .box-content {
    width: 350px;
    height: 50%;
    .avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .boxavatar {
        display: flex;
        .avatar-bifiin {
          width: 70px;
          height: 70px;
          z-index: 2;
        }
        .avatar-partner {
          width: 70px;
          height: 70px;
          z-index: 1;
          margin-left: -20px;
        }
      }
    }
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      display: flex;
      display: block;
      align-items: center;
      text-align: center;
      color: #2a3b50;
      .text-partner {
        color: #2593fc;
        margin: 0px 7px;
      }
    }
    .infor-user {
      display: flex;
      justify-content: center;
      margin-bottom: 46px;
      margin-top: 20px;
      .avatar-user {
        width: 24px;
        height: 24px;
      }
      .name {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        color: #2a3b50;
        margin-left: 10px;
      }
    }
    .description {
      display: flex;
      justify-content: space-between;
      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        display: flex;
        align-items: center;
        color: #435c7a;
        width: 80%;
      }
      .icon {
        width: 10%;
        margin-top: 10px;
      }
    }
    .section-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .btn-reject {
        width: 48%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #2a3b50;
        cursor: pointer;
        border: 1px solid #2a3b50;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        border-radius: 4px;
      }
      .btn-accept {
        width: 48%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #1e2a39;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background: #fbd448;
        border-radius: 4px;
      }
    }
  }
`;
