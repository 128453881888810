import { UserModel } from '@app/domain/UserModel';
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

const testUser = {
  id: 1,
  firstName: 'Chris',
  lastName: 'Johnson',
  imgUrl: avatarImg,
  userName: '@john1989',
  email: {
    name: 'chris.johnson@altence.com',
    verified: true,
  },
  phone: {
    number: '+18143519459',
    verified: true,
  },
  sex: 'male',
  birthday: '01/26/2022',
  lang: 'en',
  country: 'GB',
  city: 'London',
  address1: '14 London Road',
  zipcode: 5211,
  website: 'altence.com',
  socials: {
    twitter: '@altence_team',
    facebook: 'https://facebook.com/groups/1076577369582221',
    linkedin: 'https://linkedin.com/company/altence',
  },
};

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const UrlPartnerPage = (): string => {
  const UrlPartner = localStorage.getItem('UrlPartner');
  return UrlPartner ? UrlPartner : '';
};

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || '';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const persistUserUuid = (uuid: string): void => {
  localStorage.setItem('uuid', uuid);
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : testUser;
};

export const getSaveParamsEmail = () => {
  const setParamsEmail = localStorage.getItem('setParamsEmail');
  return setParamsEmail ? setParamsEmail : null;
};

export const setSaveParamsEmail = (check: string | 'true' | 'false') => {
  localStorage.setItem('setParamsEmail', check);
};

export const setSaveLoanCodeEmail = (code: string | any) => {
  localStorage.setItem('setLoanCodeEmail', code);
};

export const getSaveLoanCodeEmail = () => {
  const setLoanCodeEmail = localStorage.getItem('setLoanCodeEmail');
  return setLoanCodeEmail ? setLoanCodeEmail : null;
};

export const SetUserTouchEkycAfterLocalStorage = (check: string | 'true' | 'false') => {
  localStorage.setItem('UserTouchEkycAfter', check);
};

export const GetUserTouchEkycAfterLocalStorage = () => {
  return localStorage.getItem('UserTouchEkycAfter') ? true : false;
};

export const deleteUserTouchEkycAfter = (): void => localStorage.removeItem('UserTouchEkycAfter');
export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
export const deleteSaveParamsEmail = (): void => localStorage.removeItem('setParamsEmail');
export const deleteSaveCodeEmail = (): void => localStorage.removeItem('setLoanCodeEmail');
export const deleteInforPartner = (): void => localStorage.removeItem('inforUserPartner');

// export const deleteInforPartner = (): void => localStorage.removeItem('inforUserPartner');
