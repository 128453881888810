import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { paramsApiConfirmPayment, ResponseDataDetail, ResponseDataFund, ResponseDataLoanManagementList, ResponseDataUploadFile } from './interface';

const httpApi = initAxios({});

interface ConfigFormData extends AxiosRequestConfig {
  secured: boolean;
  ContentType: string;
}
interface UserProfileRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const UserProfileConfig: UserProfileRequestConfig = {
  secured: true,
};

const TokenConfigFormData: ConfigFormData = {
  ContentType: 'ContentType',
  secured: true,
};

export const getLoanManagementListApi = (params: string): Promise<ResponseDataLoanManagementList> => httpApi.get(`/loans/v1/users/loan-collections?${params}`, { ...UserProfileConfig });

export const getLoanManagementDetailApi = (params: string | undefined): Promise<ResponseDataDetail> => httpApi.get(`/loans/v1/users/dashboards/item/${params}`, { ...UserProfileConfig });

export const getLoanManagementDetailFundApi = (params: number): Promise<ResponseDataFund> => httpApi.get(`/loans/v1/funds/${params}`, { ...UserProfileConfig });

export const UploadImgApi = (formData: FormData): Promise<ResponseDataUploadFile> => httpApi.post('/loans/v1/files/users/upload', formData, { ...TokenConfigFormData });

export const confirmPaymentApi = (params: paramsApiConfirmPayment): Promise<ResponseDataUploadFile> => httpApi.post('/loans/v1/users/payments', params, { ...UserProfileConfig });
