import styled from 'styled-components';
import { Col, Form, Modal } from 'antd';
import { media } from '@app/styles/themes/constants';

export const Wrapper = styled.div`
  padding-top: 8%;
`;
export const BoxForm = styled(Col)``;
export const BoxImageCover = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 359.6px;
    height: 282.85px;
  }

  @media only screen and ${media.sm} {
    display: none;
  }

  @media only screen and ${media.xs} {
    display: none;
  }

  @media only screen and ${media.xl} {
    display: flex;
  }
`;

export const ModalNoti = styled(Modal)`
  .section-btn {
    display: flex;
    justify-content: center;
  }
  .title-noti {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #597ef7;
    margin-top: 20px;
  }
  .text-description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    color: #2a3b50;
    margin-bottom: 15px;
  }
  .text-note {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
    color: #3d536f;
    text-align: center;
  }
  .ant-modal-content {
    width: 675px;
    height: 265px;
    display: flex;
    justify-content: center;
  }
  span.ant-modal-close-x {
    display: none;
  }
`;

export const ModalNotiLimit = styled(Modal)`
  .section-btn {
    display: flex;
    justify-content: center;
  }
  .title-noti-limit {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #ff7a45;
  }
  .text-description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    color: #2a3b50;
    margin-bottom: 15px;
  }
  .text-note {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #3d536f;
  }
  ant-modal-content {
    width: 675px;
    height: 265px;
    display: flex;
    justify-content: center;
  }
  span.ant-modal-close-x {
    display: none;
  }
`;

export const TextResendOtp = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #c98500 !important;
  cursor: pointer;
  margin-top: 10px;
`;
export const TextResendOtpTimer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #899fbb !important;
  margin-top: 10px;
`;

export const InputsFormAntd = styled(Form)<{ cover?: string | 'show' }>`
  .text-remember-me {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3d536f;
  }
  display: flex;
  justify-content: ${(props) => {
    return props?.cover == 'show' ? 'flex-end' : 'center';
  }};

  @media only screen and ${media.sm} {
    justify-content: center;
  }

  @media only screen and ${media.xs} {
    justify-content: center;
  }

  @media only screen and ${media.xl} {
    justify-content: ${(props) => (props?.cover == 'show' ? 'flex-end' : 'center')};
  }
`;

export const InputsFormWrapper = styled.div<{ cover?: boolean | true }>`
  width: ${(props) => (props?.cover ? '69%' : '440px')};

  @media only screen and ${media.sm} {
    width: 80%;
  }

  @media only screen and ${media.xs} {
    width: 80%;
  }

  @media only screen and ${media.xl} {
    width: ${(props) => (props?.cover ? '69%' : '447px')};
  }
`;
export const InputsFormHeading = styled.div`
  margin-bottom: 35px;
  & span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    border-bottom: 2px solid #2a3b50;
  }
`;
export const InputsFormMain = styled.div``;
export const InputsFormItem = styled(Form.Item)`
  margin-top: 20px;
  margin-right: 0 !important;
`;
export const InputsFormOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  font-size: 14px;
`;

export const RememberBox = styled(Form.Item)`
  & .ant-form-item-row {
    flex-direction: row-reverse;
    gap: 10px;

    & .ant-form-item-label {
      flex: auto;
    }
  }
`;

export const InputsFormTextLink = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-color);
  margin-top: 16px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const BoxShowingMobileOtp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 9px 16px;
  margin-top: 16px;

  width: fit-content;
  height: 38px;

  background: #484f57;
  border-radius: 100px;

  & span {
    color: white;
    font-weight: 500;
    font-size: 16px;
  }
`;

export const HeadingMobileOtp = styled.div`
  & h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2a3b50;
  }
`;

export const OtpInput = styled.div`
  margin-top: 16px;
  & .otp-box {
    gap: 15px;
    margin-top: 16px;
  }

  & h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2a3b50;
  }

  & input {
    width: 37px !important;
    height: 37px !important;
    background-color: white;
    border: 1.5px solid #acb8c8;
    border-radius: 6px;
  }
`;

export const OtpInputError = styled.div`
  margin-top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff4d4f;
  min-height: 18.5px;
`;

export const BtnConfirm = styled.div`
  margin-top: 40px;
`;
export const styleTextNote = styled.div`
  margin-top: 12px;
  display: flex;
  .text-note {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #2a3b50;
  }
  .icon {
    color: red;
    margin-right: 5px;
  }
`;
export const BoxReSendOtp = styled.div``;
export const PasswordCheckList = styled.div`
  & ul.password-checklist {
    font-size: 14px;
    & li {
      display: flex;
      align-items: center;
    }
  }
`;

export const RegGuideHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--heading-color);
  margin-bottom: 24px;
  & span.h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  & span.h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }
`;

export const ContentPartner = styled.div`
  .avatar {
    display: flex;
    margin-bottom: 20px;
    .boxavatar {
      display: flex;
      .avatar-bifiin {
        width: 70px;
        height: 70px;
        z-index: 2;
      }
      .avatar-partner {
        width: 70px;
        height: 70px;
        z-index: 1;
        margin-left: -20px;
      }
    }
  }
  .text-login-with-biffin {
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #2a3b50;
  }
  .text-description {
    margin-top: 10px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #2a3b50;
    span {
      color: #2593fc;
    }
  }
`;

export const TextComfirmPolicy = styled.div`
  margin-top: 20px;
  margin-bottom: -12px;
  font-style: normal;
  font-weight: 500;
  font-size: 11.5px;
  line-height: 160%;
  color: #3d536f;
  .text-special {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
`;
