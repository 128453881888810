import React, { useEffect } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './RegisterSuccess.style';
import SuccessAnimated from '@app/assets/animated-json/91001-success.json';
import { Button } from 'antd';
import lottie from 'lottie-web';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';

const RegisterSuccess: React.FC = () => {
  const elem = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const verified = useAppSelector((state) => state.auth.verified);

  if (!verified) {
    navigate('/auth/login');
  }

  useEffect(() => {
    const completed_anim = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: elem.current,
      animationData: SuccessAnimated,
      autoplay: true,
      loop: false,
    });

    return () => {
      completed_anim.destroy();
    };
  }, []);

  return (
    <>
      <PageTitle>Đăng ký thành công</PageTitle>
      <S.Wrapper>
        <S.IconSuccess ref={elem} />
        <S.Text>Đăng ký tài khoản thành công</S.Text>
        <Button
          type={'link'}
          style={{ color: 'blue', fontWeight: 400 }}
          onClick={() => {
            localStorage.getItem('paramsPartner') ? navigate('/auth/login/connect-partner') : navigate('/auth/login');
          }}
        >
          Đăng nhập
        </Button>
      </S.Wrapper>
    </>
  );
};

export default RegisterSuccess;
