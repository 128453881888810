import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doFetchUserProfile } from '@app/modules/CustomerProfile/UpdateProfile/UpdateProfile.api';
import { CustomerProfileInterface } from '@app/modules/CustomerProfile/interfaces';
import { FetchingStatusInterface } from '@app/interfaces/interfaces';

interface ProfileSliceInterface extends FetchingStatusInterface {
  customerProfile: CustomerProfileInterface;
}

const initialState: ProfileSliceInterface = {
  customerProfile: {
    user: {
      phone: '',
      email: '',
      status: '',
    },
    fullName: '',
    dob: '',
    idCard: '',
    address: '',
    otherAddress: '',
  },
  dataResp: null,
  isLoading: false,
};

export const doGetUserProfile = createAsyncThunk('customer-profile/doGetUserProfile', async () => {
  return doFetchUserProfile();
});

export const customerProfileSlice = createSlice({
  name: 'customer-profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetUserProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(doGetUserProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dataResp = action.payload;
    });
    builder.addCase(doGetUserProfile.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default customerProfileSlice.reducer;
