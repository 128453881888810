import { Dispatch, useReducer } from 'react';
import { UPDATE_FILTERS_DASHBOARD, UPDATE_MODALS_DASHBOARD } from './Action';
import { ReducerType } from './inteface';

export const initDashboard = {
  stateDashboard: {
    form: null,
    filters: {
      status: '',
    },
    stateModal: {
      stateModalDisbursement: false,
      openModalNotiEkyc: false,
    },
  },
  dispatchDashboard: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_DASHBOARD:
      return {
        ...state,
        filters: action.filtersDashboard,
      };
    case UPDATE_MODALS_DASHBOARD:
      return {
        ...state,
        stateModal: action.stateModal,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useDashboardReducer = () => {
  return useReducer(reducer, initDashboard.stateDashboard);
};
