import { Dispatch, useReducer } from 'react';
import { UPDATE_AMOUNT_PAYMENT, UPDATE_FILTERS_LOANMANAGEMENT, UPDATE_MODALS_LOANMANAGEMENT } from './Action';
import { ReducerType } from './interface';

export const initLoanManagement = {
  stateLoanManagement: {
    filters: {
      status: '',
      key: '',
      point: '',
      pageSize: 10,
      pageIndex: 1,
    },
    modals: {
      isModalOpenSuccess: false,
      isModalOpenPayment: false,
      isModalOpenConfirm: false,
    },
    amount: {
      amountPayment: null,
    },
  },
  dispatchLoanManagement: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_LOANMANAGEMENT:
      return {
        ...state,
        filters: action.filtersLoanManagement,
      };
    case UPDATE_AMOUNT_PAYMENT:
      return {
        ...state,
        filters: action.amount,
      };
    case UPDATE_MODALS_LOANMANAGEMENT:
      return {
        ...state,
        modals: action.modals,
      };

    default:
      return {
        ...state,
      };
  }
};

export const useLoanManagementReducer = () => {
  return useReducer(reducer, initLoanManagement.stateLoanManagement);
};
