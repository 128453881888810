import React from 'react';
import { WithChildrenProps } from '@app/types/generalTypes';
import * as S from '@app/modules/Auth/Auth.style';
import { Row } from 'antd';
import IncomeImageCover from '@app/assets/images/signup-cover-income.svg';

interface AuthMainLayoutProps extends WithChildrenProps {
  cover?: boolean | true;
}

const MainLayout: React.FC<AuthMainLayoutProps> = ({ children, cover }) => {
  return (
    <S.Wrapper>
      <Row gutter={[0, 0]}>
        <S.BoxForm xs={24} sm={24} xl={cover == undefined || cover ? 12 : 24}>
          {children}
        </S.BoxForm>
        {cover == undefined || cover ? (
          <S.BoxImageCover xs={24} sm={24} xl={12}>
            <img src={IncomeImageCover} />
          </S.BoxImageCover>
        ) : null}
      </Row>
    </S.Wrapper>
  );
};

export default MainLayout;
