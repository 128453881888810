import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .box-content {
    padding-top: 250px;
    width: 391px;
    .title {
      margin-top: 40px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #40a9ff;
      justify-content: center;
    }
    .description {
      margin-top: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #2a3b50;
    }
    .description-note {
      display: flex;
      margin-top: 30px;
      justify-content: space-between;
      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        display: flex;
        align-items: center;
        color: #435c7a;
        width: 79%;
      }
      .icon {
        width: 10%;
        margin-top: 10px;
      }
    }
    .section-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .btn-reject {
        width: 48%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #2a3b50;
        cursor: pointer;
        border: 1px solid #2a3b50;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        border-radius: 4px;
      }
      .btn-accept {
        width: 48%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #1e2a39;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background: #fbd448;
        border-radius: 4px;
      }
    }
  }
`;
