import styled from 'styled-components';
import { Button } from 'antd';

export const StyleButtonBlue = styled(Button)`
  background: #597ef7;
  border-radius: 4px;
  padding: 4px 24px;
  height: unset !important;
  cursor: pointer;
  color: #fff;
  &.ant-btn:active,
  &.ant-btn:focus {
    color: #fff !important;
    background-color: #597ef7 !important;
  }
  &.ant-btn:hover {
    color: unset !important;
    background-color: unset !important;
  }
`;
