import { Form, Input, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { LoanManagementContext } from '../Context';
import IconPayment from '@app/assets/icons/icon-payment-1.svg';

import * as S from '../LoanManagement.style';
import ButtonDetail from '@app/components/common/buttons/ButtonDetail/ButtonDetail';
import ModalPaymentConfirm from './ModalPaymentConfirm';
import { ResponseDataDetail } from '../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import { updateAmountPayment, updateModalLoanManagement } from '../Action';
//1

type Props = {
  data?: ResponseDataDetail;
};
export default function ModalSectionPayment(props: Props) {
  const [saveValueInput, setsaveValueInput] = useState(
    props?.data?.disbursedAmount
      ? props?.data?.disbursedAmount + props?.data?.accruedFeeAmount - (props?.data?.interestPaidAmount + props?.data?.principalPaidAmount + props?.data?.feePaidAmount)
      : '',
  );
  const { stateLoanManagement, dispatchLoanManagement } = useContext(LoanManagementContext);

  function handleOk() {
    dispatchLoanManagement(updateModalLoanManagement({ ...stateLoanManagement?.modals, isModalOpenPayment: false }));
  }

  function handleCancel() {
    dispatchLoanManagement(updateModalLoanManagement({ ...stateLoanManagement?.modals, isModalOpenPayment: false }));
  }

  function showModalConfirm() {
    if (saveValueInput >= 0) {
      dispatchLoanManagement(updateModalLoanManagement({ ...stateLoanManagement?.modals, isModalOpenConfirm: true }));
    }
  }

  function onChangeAmountPayment(e: any) {
    if (e) {
      setsaveValueInput(e.target.value);
      dispatchLoanManagement(updateAmountPayment({ ...stateLoanManagement?.amount, amountPayment: e.target.value }));
    }
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  return (
    <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
      <S.StyleModalPayment title="Thanh toán khoản ứng vốn" footer={null} open={stateLoanManagement?.modals?.isModalOpenPayment} onOk={handleOk} onCancel={handleCancel}>
        <div className="box-content">
          <Row className="section-icon">
            <img src={IconPayment} alt="" />
          </Row>
          <Row className="text-confirm">
            <div className="box-title">
              Bạn muốn thanh toán khoản ứng vốn <span className="text-confirm-id"> {props.data ? props.data?.code : ''}</span>?{' '}
            </div>
          </Row>
          <Row className="table">
            <div className="box-table">
              <div className="line">
                <div className="label">Tổng tiền ứng vốn</div>
                <div className="value">{props?.data ? formatNumberWithCommas(props?.data?.loanAmount) : '0'}</div>
              </div>
              <div className="line">
                <div className="label">Phí quản lý ứng vốn (1.2%)</div>
                <div className="value">{props?.data ? formatNumberWithCommas(props?.data?.accruedFeeAmount) : '0'}</div>
              </div>
              <div className="line">
                <div className="label">Tổng tiền phải thanh toán</div>
                <div className="value value-blue">
                  {props.data
                    ? formatNumberWithCommas(
                        props?.data?.disbursedAmount + props?.data?.accruedFeeAmount - (props?.data?.interestPaidAmount + props?.data?.principalPaidAmount + props?.data?.feePaidAmount),
                      )
                    : '0'}
                </div>
              </div>
            </div>
            <div className="section-amount">
              <div className="title-amount">Số tiền bạn muốn thanh toán</div>
              <div className="box-amount-number">
                {/* <div className="amount-style">100</div> */}
                <Form.Item
                  name="amount"
                  rules={[
                    { required: true, message: 'Bạn cần nhập số tiền bạn muốn thanh toán!' },
                    () => ({
                      validator(_, value) {
                        if (value < 0) {
                          return Promise.reject('Số tiền bạn thanh toán phải lớn hơn 0');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="number"
                    onChange={onChangeAmountPayment}
                    min={0}
                    defaultValue={
                      props.data ? props?.data?.disbursedAmount + props?.data?.accruedFeeAmount - (props?.data?.interestPaidAmount + props?.data?.principalPaidAmount + props?.data?.feePaidAmount) : 0
                    }
                  ></Input>
                </Form.Item>
              </div>
            </div>
          </Row>
          <Row className="section-btn">
            <ButtonDetail function={showModalConfirm} label="Xác nhận" class="btn-style"></ButtonDetail>
          </Row>
        </div>
        {props?.data && <ModalPaymentConfirm data={props?.data} amountCustomerWannaPayment={props && saveValueInput}></ModalPaymentConfirm>}
      </S.StyleModalPayment>
    </Form>
  );
}
