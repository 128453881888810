import React from 'react';
import * as S from '../ConsumerLayout.style';
// import { useTranslation } from 'react-i18next';
import Logo from '@app/assets/logo-v3.svg';

const Header: React.FC = () => {
  // const { t } = useTranslation();
  return (
    <S.WrapperHeader>
      <S.HeaderLogo>
        <img src={Logo} className={'logo'} />
        {/* <span>{t('common.projectName')}</span> */}
      </S.HeaderLogo>
    </S.WrapperHeader>
  );
};

export default Header;
