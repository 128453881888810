import React from 'react';
import * as S from '../DashboardPage.styles';
import IconNotiEkycAccount from '@app/assets/icons/icon-dashboard-noti-ekyc.svg';
import ButtonBlue from '@app/components/common/buttons/ButtonBlue/ButtonBlue';
import IconModalNotiEkyc from '@app/assets/icons/icon-modal-noti-ekyc-dashboard.svg';
import ButtonDetail from '@app/components/common/buttons/ButtonDetail/ButtonDetail';

type Props = {
  openModalNotiEkyc: boolean;
  handleEkycAfter: () => void;
  handleOpenModalNoti: () => void;
};
export default function SectionNotiEkycAccount(props: Props) {
  // const text1 = <span className="text-tooltip">Giao dịch chờ duyệt</span>;
  // const { stateDashboard, dispatchDashboard } = useContext(DashboardContext);

  function moveEkycStart() {
    location.href = '/ekyc/step-verify';
  }

  return (
    <S.SectionNotiEkycAccount>
      <div className="box-start">
        <img src={IconNotiEkycAccount} alt="" />
        <div className="text-noti">Hoàn thành xác thực danh tính ngay để sử dụng tất cả các dịch vụ của chúng tôi</div>
      </div>
      <div className="box-end">
        <ButtonBlue function={props?.handleOpenModalNoti} label="Xác thực ngay"></ButtonBlue>
      </div>
      <S.StyleModalNotiEkyc closeIcon={null} footer={null} open={props?.openModalNotiEkyc}>
        <div className="box-content-modal">
          <div className="row-img">
            <img src={IconModalNotiEkyc} alt="" />
          </div>
          <div className="row-text">Hoàn thành xác thực danh tính ngay để sử dụng tất cả các dịch vụ của chúng tôi</div>
          <div className="box-btn">
            <ButtonDetail function={props?.handleEkycAfter} label="Để sau" type="btn-white" class="item"></ButtonDetail>
            <ButtonDetail function={moveEkycStart} label="Xác thực ngay" class="item"></ButtonDetail>
          </div>
        </div>
      </S.StyleModalNotiEkyc>
    </S.SectionNotiEkycAccount>
  );
}
