import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { persistToken, setSaveLoanCodeEmail, setSaveParamsEmail } from '@app/services/localStorage.service';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  const { tokenEkyc } = useParams();
  const { code } = useParams();
  const { email } = useParams();

  useEffect(() => {
    if (email == 'email' && code) {
      setSaveParamsEmail('true');
      setSaveLoanCodeEmail(code);
    }
  }, [code, email]);

  useEffect(() => {
    if (tokenEkyc) {
      persistToken(tokenEkyc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return tokenEkyc ? <>{children}</> : token ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
