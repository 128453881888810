import styled from 'styled-components';
import { Button } from 'antd';

export const StyleButtonDetail = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 17px;
  background: #fbd448;
  border-radius: 4px;
  height: unset;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #1e2a39;
  border: none;
  &.btn-white {
    background: #ffffff !important;
    border-radius: 4px;
    border: 1px solid #1e2a39 !important;
    color: #1e2a39 !important;
    cursor: pointer;
  }
`;
