import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { ParamsChangeInforPaymentType, paramsChangePassword, paramsInforUserApi, responseApiGetInforUser, ResponseChangePasswordApi, ResponseInforUserApi } from '../interfaces';

const httpApi = initAxios({});

interface UserProfileRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const UserProfileConfig: UserProfileRequestConfig = {
  secured: true,
};

const ChangePasswdConfig: UserProfileRequestConfig = {
  secured: true,
};

export const doFetchUserProfile = (): Promise<any> => httpApi.get('/users/v1/me/profile', { ...UserProfileConfig });
export const doChangePasswd = (): Promise<any> => httpApi.get('/auth/v1/me/change-password', { ...ChangePasswdConfig });

export const getUserProfileApi = (params: string): Promise<responseApiGetInforUser> => httpApi.get(`/loans/v1/users/profile/${params}`, { ...UserProfileConfig });

export const ChangePasswdApi = (params: paramsChangePassword): Promise<ResponseChangePasswordApi> => httpApi.put('/users/v1/me/change-password', params, { ...UserProfileConfig });

export const ChangeInforUserApi = (uuid: string, params: paramsInforUserApi, paramsChangeInforPayment?: ParamsChangeInforPaymentType | undefined | string): Promise<ResponseInforUserApi> =>
  httpApi.put(`/loans/v1/users/profile/${uuid}?${paramsChangeInforPayment}`, params, { ...UserProfileConfig });
