import React from 'react';
import styled from 'styled-components';
import { useResponsive } from '@app/hooks/useResponsive';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.ul`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  color: #d9d9d9;
  gap: 35px;
  margin: 0;
  span {
    margin-right: 20px;
  }
  & li {
    list-style-type: none;

    & a {
      color: white;
      &:hover {
        /* text-decoration: underline; */
        color: #fbd448;
      }
      &:active {
        /* text-decoration: underline; */
        color: #fbd448;
      }
      &:focus {
        /* text-decoration: underline; */
        color: #fbd448;
      }
    }
  }
`;

interface ItemsInterface {
  key: string;
  label: string;
  url: string;
}
const Items: ItemsInterface[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    url: '/boards',
  },
  {
    key: 'loan-management',
    label: 'Quản lý giao dịch',
    url: '/loan-management',
  },
];

const Menus: React.FC = () => {
  const { desktopOnly, isBigScreen } = useResponsive();

  //   <Wrapper>
  //   {Items.map((item) => {
  //     return (
  //       <li key={item.key}>
  //         <a href={item.url}>{item.label}</a>
  //       </li>
  //     );
  //   })}
  // </Wrapper>
  if (desktopOnly || isBigScreen) {
    return (
      <Wrapper>
        <nav className="navbar navbar-default navbar-static-top">
          {Items.map((item, index) => (
            <NavLink
              key={index}
              to={item.url}
              style={({ isActive }) => {
                return {
                  color: isActive ? '#FBD448' : '#D9D9D9',
                };
              }}
            >
              {({ isActive }) => <span className={isActive ? 'active' : ''}>{item.label}</span>}
            </NavLink>
          ))}
        </nav>
      </Wrapper>
    );
  }

  return null;
};

export default Menus;
