import { httpApiMock } from '@app/api/mocks/http.api.mock';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
httpApiMock.onPut('/v1/auth/register/get-otp').reply((config) => {
  return [
    200,
    {
      status: true,
    },
  ];
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
httpApiMock.onPut('/v1/auth/register/confirm-otp').reply((config) => {
  return [
    200,
    {
      status: true,
    },
  ];
});
