import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/modules/Auth/Auth.slice';
import customerProfileReducer from '@app/modules/CustomerProfile/CustomerProfile.slice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import breadcrumbReducer from '@app/store/slices/breadcrumbSlice';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  breadcrumb: breadcrumbReducer,
  customerProfile: customerProfileReducer,
};
