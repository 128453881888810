import React, { lazy } from 'react';
import { AppRouterInterface } from '@app/interfaces/interfaces';
import Detail from './LoanManagement/SectionDetail/Detail';

const LoanManagement = lazy(() => {
  return import('./LoanManagement/LoanManagement');
});

const StartVerify = lazy(() => {
  return import('./Ekyc/Page/StartVerify');
});
const DashBoard = lazy(() => {
  return import('./DashboardPages/Main');
});
const StepVerify = lazy(() => {
  return import('./Ekyc/Page/StepVerify');
});
const EkycMobile = lazy(() => {
  return import('./Ekyc/Page/Mobile/EkycMobile');
});
const EkycSuccessMobile = lazy(() => {
  return import('./Ekyc/Page/EkycSuccessMobile');
});
const EkycSuccessDesktop = lazy(() => {
  return import('./Ekyc/Page/EkycSuccessDesktop');
});

const EkycFail = lazy(() => {
  return import('./Ekyc/Page/EkycFail');
});
const EkycWaitForApproval = lazy(() => {
  return import('./Ekyc/Page/EkycWaitForApproval');
});
const EkycWaitForApprovalDesktop = lazy(() => {
  return import('./Ekyc/Page/EkycWaitForApprovalDesktop');
});
const EkycMobileStartVerify = lazy(() => {
  return import('./Ekyc/Page/Mobile/EkycMobileStartVerify');
});

const CustomerPageRoutes: AppRouterInterface[] = [
  {
    path: '/boards',
    element: <DashBoard />,
  },
  {
    path: '/loan-management',
    element: <LoanManagement />,
  },
  {
    path: `/loan-management/:code/:email`,
    element: <Detail />,
  },
  {
    path: `/ekyc/start-verify`,
    element: <StartVerify />,
  },
  {
    path: `/ekyc/step-verify`,
    element: <StepVerify />,
  },
  {
    path: `/ekyc/start-mobile/:uuid/:tokenEkyc`,
    element: <EkycMobile />,
  },
  {
    path: `/ekyc/success-mobile`,
    element: <EkycSuccessMobile />,
  },
  {
    path: `/ekyc/success/:uuid`,
    element: <EkycSuccessDesktop />,
  },

  {
    path: `/ekyc/wait-for-approval/:uuid`,
    element: <EkycWaitForApprovalDesktop />,
  },
  {
    path: `/ekyc/fail`,
    element: <EkycFail />,
  },
  {
    path: `/ekyc/wait-for-approval-mobile`,
    element: <EkycWaitForApproval />,
  },
  {
    path: `/ekyc/start-mobile/start-verify`,
    element: <EkycMobileStartVerify />,
  },
];

export default CustomerPageRoutes;
